import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
// import gsap from "gsap";
import { Link, navigate } from "gatsby"

import { staggerText, handleHover, handleHoverExit } from "./Animations"

const Hamburger = ({ state, color }) => {
  // Create varibles of our dom nodes
  let menuLayer = useRef(null)
  let reveal1 = useRef(null)
  let reveal2 = useRef(null)
  // let cityBackground = useRef(null);
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  // let info = useRef(null);

  useEffect(() => {
    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      // If menu is closed and we want to open it.

      // staggerRevealClose(reveal2, reveal1);
      gsap.to([reveal2, reveal1], {
        duration: 0.8,
        height: 0,
        ease: "power3.inOut",
        stagger: {
          amount: 0.07,
        },
      })

      if (window && document) {
        // document.body.style.overflow = 'hidden';
        document.body.style.removeProperty("overflow")
      }

      // Set menu to display none
      gsap.to(menuLayer, { duration: 1, css: { display: "none" } })
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      gsap.to(menuLayer, { duration: 0, css: { display: "block" } })
      //Allow menu to have height of 100%
      gsap.to([reveal1, reveal2], {
        duration: 0,
        opacity: 1,
        height: "100%",
      })
      // staggerReveal(reveal1, reveal2);
      gsap.from([reveal1, reveal2], {
        duration: 0.8,
        height: 0,
        transformOrigin: "right top",
        skewY: 2,
        ease: "power3.inOut",
        stagger: {
          amount: 0.1,
        },
      })
      if (window && document) {
        document.body.style.overflow = "hidden"
        // document.body.style.removeProperty('overflow');
      }
      // fadeInUp(info);
      // gsap.from(info, {
      //   y: 60,
      //   duration: 1,
      //   delay: 0.2,
      //   opacity: 0,
      //   ease: "power3.inOut"
      // });
      staggerText(line1, line2, line3, line4, line5)
      // gsap.from([line1, line2, line3], {
      //   duration: 0.8,
      //   y: 100,
      //   delay: 0.1,
      //   ease: "power3.inOut",
      //   stagger: {
      //     amount: 0.3
      //   }
      // });
    }
  }, [state])

  const navigateTo = route => {
    if (window && document) {
      // document.body.style.overflow = 'hidden';
      document.body.style.removeProperty("overflow")
    }
    navigate(route)
  }
  return (
    <div ref={el => (menuLayer = el)} className="hamburger-menu">
      <div
        ref={el => (reveal1 = el)}
        // BLACK BACKGROUND
        className="menu-secondary-background-color"
      />
      {/* MENU LAYER */}
      <div ref={el => (reveal2 = el)} className="menu-layer">
        {/* CONTENT GOES HERE */}
        <div style={{ background: `${color}` }} className="menu-container">
          <div onClick={() => navigateTo("/")} to="/">
            {/* <Link to="/"> */}
            <div
              onMouseEnter={e => handleHover(e)}
              onMouseOut={e => handleHoverExit(e)}
              ref={el => (line1 = el)}
              className="menu-item menu-item-stickers"
            >
              Home
            </div>
            {/* </Link> */}
          </div>
          {/* <Link to="/journals"> */}
          <div onClick={() => navigateTo("/journals")}>
            <div
              onMouseEnter={e => handleHover(e)}
              onMouseOut={e => handleHoverExit(e)}
              ref={el => (line1 = el)}
              className="menu-item menu-item-stickers"
            >
              Journals
            </div>
          </div>
          {/* <Link to="/wall"> */}
          {/* <div onClick={() => navigateTo("/wall")}>
            <div
              onMouseEnter={(e) => handleHover(e)}
              onMouseOut={(e) => handleHoverExit(e)}
              ref={(el) => (line1 = el)}
              className="menu-item menu-item-stickers"
            >
              Wall
            </div>
          </div> */}
          <div onClick={() => navigateTo("/about")}>
            <div
              onMouseEnter={e => handleHover(e)}
              onMouseOut={e => handleHoverExit(e)}
              ref={el => (line2 = el)}
              className="menu-item menu-item-about"
            >
              About
            </div>
          </div>
          <div onClick={() => navigateTo("/goodjournals")}>
            <div
              onMouseEnter={e => handleHover(e)}
              onMouseOut={e => handleHoverExit(e)}
              ref={el => (line3 = el)}
              className="menu-item menu-item-doinggood"
            >
              Doing Good
            </div>
          </div>
          <div onClick={() => navigateTo("/cart")}>
            <div
              onMouseEnter={e => handleHover(e)}
              onMouseOut={e => handleHoverExit(e)}
              ref={el => (line4 = el)}
              className="menu-item menu-item-stickers"
            >
              Cart
            </div>
          </div>
          <div onClick={() => navigateTo("/faq")}>
            <div
              onMouseEnter={e => handleHover(e)}
              onMouseOut={e => handleHoverExit(e)}
              ref={el => (line5 = el)}
              className="menu-item menu-item-faq"
            >
              FAQ
            </div>
          </div>

          {/* BOTTOM */}
          <div className="menu-bottom-container">
            <Link to="/privacy">
              <div className="menu-bottom-privacy">Privacy</div>
            </Link>
            |
            <Link to="/termsofservice">
              <div className="menu-bottom-terms">Terms</div>
            </Link>
          </div>

          {/* SOCIAL MEDIA ICONS */}
          <div className="menu-social-icons-container">
            <a
              href="https://www.facebook.com/Cat-Bear-115246910018053/"
              target="_blank"
            >
              <svg
                className="social-icon-facebook"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Facebook icon</title>
                <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" />
              </svg>
            </a>
            <a href="https://www.instagram.com/catbear.love" target="_blank">
              <svg
                className="social-icon-instagram"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h362c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm-180 390c-74.441406 0-135-60.558594-135-135s60.558594-135 135-135 135 60.558594 135 135-60.558594 135-135 135zm150-240c-24.8125 0-45-20.1875-45-45s20.1875-45 45-45 45 20.1875 45 45-20.1875 45-45 45zm0 0" />
                <path d="m407 90c-8.277344 0-15 6.722656-15 15s6.722656 15 15 15 15-6.722656 15-15-6.722656-15-15-15zm0 0" />
                <path d="m257 150c-57.890625 0-105 47.109375-105 105s47.109375 105 105 105 105-47.109375 105-105-47.109375-105-105-105zm0 0" />
              </svg>
            </a>
            {/* <svg className="social-icon-pinterest" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Pinterest icon</title><path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"/></svg> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hamburger
