import "../styles/index.scss"
import React, { useState, useContext, useEffect } from "react"
import reduce from "lodash/reduce"
import Hamburger from "./Hamburger"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import gsap from "gsap"

// import { fadeInDown } from './Animations';
import Context from "../context/StoreContext"

import CookieConsent from "react-cookie-consent"
import ReactPixel, { fbq } from "react-facebook-pixel"

const isActive = ({ isCurrent }) => {
  return { className: isCurrent ? "active" : "navlink" }
}

const NavLink = props => <Link getProps={isActive} {...props} />

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(Context)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  // console.log('quuantity', total);
  return [total !== 0, total]
}

const Header = props => {
  // console.log("props", props)
  const [hasItems, quantity] = useQuantity()
  const {
    store: { checkout, consent },
    initConsent,
    activateConsent,
  } = useContext(Context)
  // console.log('consent', consent);
  // console.log('setConsent', setConsent);

  // console.log(window.fbq);

  // console.log('ReactPixel', ReactPixel.fbq);
  useEffect(() => {
    if (!consent) {
      // console.log('[initConsent, consent]', [ initConsent, consent ]);
      initConsent()
    } else {
      // console.log('reached');

      activateConsent()
    }

    ReactPixel.track("ViewContent", {
      path: props.path,
      title: props.title ? props.title : "",
    })
  }, [])

  // useEffect(() => {
  // 	effect
  // 	return () => {
  // 		cleanup
  // 	};
  // }, [input]);

  // const menuLeftContainer = useRef();
  // const menuRightContainer = useRef();

  // State of our Menu
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "Menu",
  })
  // State of our button
  const [disabled, setDisabled] = useState(false)

  // useEffect(() => {
  // 	gsap.set(menuLeftContainer.current, { autoAlpha: 1 });
  // 	gsap.set(menuRightContainer.current, { autoAlpha: 1 });

  // 	fadeInDown(menuLeftContainer.current, 0.5, 1.25);
  // 	fadeInDown(menuRightContainer.current);
  // }, []);

  // Toggle menu
  const handleMenu = () => {
    disableMenu()
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "Close",
      })
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "Menu",
      })
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "Close",
      })
    }
  }

  // Determine if our menu button should be disabled
  const disableMenu = () => {
    setDisabled(!disabled)
    setTimeout(() => {
      setDisabled(false)
    }, 1200)
  }

  // const handleHover = (e) => {
  // 	handleHoverMenuItem(e);
  // };
  // const handleHoverExit = (e) => {
  // 	handleHoverExitMenuItem(e);
  // };

  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang: "en" }} />
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses="consent-container"
        contentClasses="consent-content"
        buttonClasses="consent-button"
        onAccept={({ acceptedByScrolling }) => {
          ReactPixel.grantConsent()
          activateConsent()

          // console.log('typeof ReactPixel.grantConsent', typeof ReactPixel.grantConsent);
          // if (acceptedByScrolling) {
          // 	alert('Accept was triggered by user scrolling');
          // } else {
          // 	alert('Accept was triggered by clicking the Accept button');
          // }
        }}
      >
        This website uses cookies.{" "}
        <Link style={{ color: "rgb(34, 178, 242)" }} to="/cookies">
          Learn More
        </Link>
      </CookieConsent>
      <header>
        <div className="header-banner">

        WINTER SALE: 15% Off Store Wide
        </div>
        {/* PUT HEADER HERE */}
        <div className="menu-confirm">
          <button
            className="menu-button menu-center-item"
            disabled={disabled}
            onClick={handleMenu}
          >
            {state.menuName}
          </button>
        </div>

        <div className="menu-right-container">
          <div className="menu-right-item">
            <NavLink className="menu-right-item" to="/">
              Home
            </NavLink>
          </div>

          <div className="menu-right-item">
            <NavLink className="menu-right-item" to="/journals">
              Journals
            </NavLink>
          </div>
          {/* <div className="menu-right-item">
            <NavLink className="menu-right-item" to="/wall">
              Wall
            </NavLink>
          </div> */}
          <div className="menu-right-item">
            <NavLink className="menu-right-item" to="/goodjournals">
              Doing Good
            </NavLink>
          </div>
          <div className="menu-right-item">
            <NavLink className="menu-right-item" to="/faq">
              FAQ
            </NavLink>
          </div>
          <div className="menu-right-item">
            <NavLink className="menu-right-item" to="/about">
              About
            </NavLink>
          </div>
        </div>

        {/* CART COUNT */}
        <Link to="/cart">
          <div className="cart-count">
            {/* {hasItems && <span className="cart-count-quantity">{quantity}</span>} */}
            {<span className="cart-count-quantity">{quantity}</span>}
          </div>
        </Link>

        <Hamburger state={state} color={props.color} />
      </header>
    </React.Fragment>
  )
}

export default Header
